import styled from "@emotion/styled";
import React from "react";

const SearchBoxStyles = styled.div`
  position: relative;

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Emagnify%3C/title%3E%3Cpath fill='${(
      props,
    ) =>
      encodeURIComponent(
        props.theme.list?.search,
      )}' d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z' /%3E%3C/svg%3E");
    display: inline-block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    left: 0.5rem;
    top: 0.35rem;
  }

  input {
    width: 100%;
    padding: 0.5rem 1rem;
    padding-left: 2rem;
    border: 1px solid ${(props) => props.theme.list?.search};
    border-radius: 1e10px;
  }
`;

export default function (props: React.HTMLProps<HTMLInputElement>) {
  return (
    <SearchBoxStyles className="search-box">
      <input {...props} type="text" />
    </SearchBoxStyles>
  );
}

import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { graphql } from "gatsby";
import type { PageProps } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import i18n from "../../utils/i18n";
import breakpoints from "../../utils/breakpoints";
import Tag from "../../components/Tag";
import langAtom from "../../atoms/lang";
import SearchBox from "../../components/SearchBox";
import match from "../../utils/kanaMatch";

const TagsListPageStyles = styled.div`
  margin: 1rem;

  .tag-list {
    max-width: ${breakpoints.values.lg}px;
    margin: auto;
    margin-bottom: 40px;

    .search-box {
      width: 100%;
      max-width: 600px;
      margin: auto;
    }

    .tag-list-entries {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px 10px;

      margin-top: 20px;

      .tag-list-entry {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;

        padding: 6px;
      }
    }
  }
`;

type DataProps = {
  allSanityTag: {
    nodes: {
      _createdAt: string;
      _updatedAt: string;
      _id: string;
      tag_id: {
        current: string;
      };
      related_content: {
        _type: "article" | "project";
      }[];
      label_en: string;
      label_ja: string;
    }[];
  };
};

export default function TagsPage(props: PageProps<DataProps>) {
  const [searchText, setSearchText] = useState("");
  const [siteLang] = useRecoilState(langAtom);

  let tags = props.data.allSanityTag.nodes || [];

  return (
    <TagsListPageStyles>
      <div className="tag-list">
        <h1>{i18n("tags.title")}</h1>
        <SearchBox
          id="tags-list-search"
          onChange={(e) => setSearchText((e.target as HTMLInputElement).value)}
        />
        <div className="tag-list-entries">
          {tags
            .sort((a, b) => b.related_content.length - a.related_content.length)
            .map((tag) => (
              <div
                key={tag._id}
                className="tag-list-entry"
                css={css`
                  ${match(
                    siteLang === "ja" ? tag.label_ja : tag.label_en,
                    searchText,
                  )
                    ? ""
                    : "display: none !important;"}
                `}
              >
                <AniLink fade key={tag._id} to={`/tags/${tag.tag_id.current}`}>
                  <Tag
                    className="tag-card-tag"
                    label={siteLang === "ja" ? tag.label_ja : tag.label_en}
                    id={tag.tag_id.current}
                  />
                </AniLink>
                {(() => {
                  const projectCount = tag.related_content.filter(
                    (c) => c._type === "project",
                  ).length;
                  const articleCount = tag.related_content.filter(
                    (c) => c._type === "article",
                  ).length;
                  return (
                    <>
                      {projectCount > 0 && (
                        <span>
                          {(i18n("tags.projects") as Function)(projectCount)}
                        </span>
                      )}
                      {articleCount > 0 && (
                        <span>
                          {(i18n("tags.articles") as Function)(articleCount)}
                        </span>
                      )}
                    </>
                  );
                })()}
              </div>
            ))}
        </div>
      </div>
    </TagsListPageStyles>
  );
}

export const query = graphql`
  query {
    allSanityTag(sort: { order: DESC, fields: [_createdAt] }) {
      nodes {
        _createdAt
        _updatedAt
        _id
        tag_id {
          current
        }
        related_content {
          ... on SanityArticle {
            _type
          }
          ... on SanityProject {
            _type
          }
        }
        label_en
        label_ja
      }
    }
  }
`;

export { default as Head } from "../../core/Head";

export default function match(str1: string, str2: string): boolean {
  const normalisedStr1 = str1
    .replace(/[\u30a1-\u30f6]/g, (match) =>
      String.fromCharCode(match.charCodeAt(0) - 0x60),
    )
    .toLowerCase();
  const normalisedStr2 = str2
    .replace(/[\u30a1-\u30f6]/g, (match) =>
      String.fromCharCode(match.charCodeAt(0) - 0x60),
    )
    .toLowerCase();
  return normalisedStr1.match(normalisedStr2) ||
    normalisedStr2.match(normalisedStr1)
    ? true
    : false;
}
